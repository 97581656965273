import React from 'react'
import { PostSidebar } from './PostSidebar'

export const PageLayout = ({ children, page }) => {
  return (
    <>
      <main className="main-content page">{children}</main>
      {page && <PostSidebar toc={page.tableOfContents} />}
    </>
  )
}
